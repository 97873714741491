<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="A鈥揨 Directory"
    backgroundColor="red"
    textColor="white"
    diagonal="dark"
    size="sm"
    align="start"
  />
  <div class="container my-4">
    <Directory :dataURL="`/index.xml`" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import Directory from "@/components/Directory.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "A-Z",
  metaInfo: {
    title: "A-Z Directory of 杏吧原创 Resources",
    description:
      "Explore the campuses, programs and communities that make the 杏吧原创 a unique educational experience.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {};
  },
  components: {
    PageHeader,
    Directory,
    Breadcrumb,
  },
};
</script>

<style lang="scss"></style>
